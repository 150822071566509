<template>
  <div class="w-75 text-center" ref="box" style="z-index: 1">
    <transition name="fade">
      <div v-show="show" class="login-form text-left login-signin">
        <div class="mb-10 mb-lg-20 animate__animated animate__fadeInUp">
          <h3 class="font-size-h1">Tizimga kirish</h3>
        </div>

        <b-form class="form" @submit.stop.prevent="onSubmit">
          <b-form-group
            id="example-input-group-1"
            label=""
            label-for="example-input-1"
          >
            <label class="font-size-h6 font-weight-bolder text-dark"
              >Login (username)</label
            >
            <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              id="example-input-1"
              name="example-input-1"
              placeholder="Loginni kiriting (username)"
              v-model="$v.form.username.$model"
              :state="validateState('username')"
              aria-describedby="input-1-live-feedback"
              ref="usernameRef"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              Login kiritish shart!
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="example-input-group-2"
            label=""
            label-for="example-input-2"
          >
            <label class="font-size-h6 font-weight-bolder text-dark"
              >Parol</label
            >
            <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              type="password"
              id="example-input-2"
              name="example-input-2"
              placeholder="Parolni kiriting"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-2-live-feedback"
              ref="passwordRef"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback">
              Parolni kiritish shart!
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="mb-4" style="min-height: 30px">
            <label v-if="errorText" class="font-size-h6 text-danger">
              {{ errorOptions[errorText] || "Noma'lum xato" }}
            </label>
          </div>

          <div
            class="
              form-group
              d-flex
              flex-wrap
              justify-content-between
              align-items-center
            "
            style="height: 1.88rem; max-height: 1.88rem"
          >
            <button
              style="letter-spacing: 2px"
              ref="kt_login_signin_submit"
              class="
                btn btn-primary
                font-weight-bold
                px-5
                py-2
                font-size-5
                text-uppercase
              "
            >
              <template v-if="loader">
                <b-spinner
                  class="text-center"
                  style="width: 1.9rem; height: 1.9rem"
                ></b-spinner>
              </template>

              <template v-else>
                <span class="svg-icon svg-icon-white svg-icon-2x"
                  ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Navigation/Sign-in.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(9.000000, 12.000000) rotate(-270.000000) translate(-9.000000, -12.000000) "
                        x="8"
                        y="6"
                        width="2"
                        height="12"
                        rx="1"
                      />
                      <path
                        d="M20,7.00607258 C19.4477153,7.00607258 19,6.55855153 19,6.00650634 C19,5.45446114 19.4477153,5.00694009 20,5.00694009 L21,5.00694009 C23.209139,5.00694009 25,6.7970243 25,9.00520507 L25,15.001735 C25,17.2099158 23.209139,19 21,19 L9,19 C6.790861,19 5,17.2099158 5,15.001735 L5,8.99826498 C5,6.7900842 6.790861,5 9,5 L10.0000048,5 C10.5522896,5 11.0000048,5.44752105 11.0000048,5.99956624 C11.0000048,6.55161144 10.5522896,6.99913249 10.0000048,6.99913249 L9,6.99913249 C7.8954305,6.99913249 7,7.89417459 7,8.99826498 L7,15.001735 C7,16.1058254 7.8954305,17.0008675 9,17.0008675 L21,17.0008675 C22.1045695,17.0008675 23,16.1058254 23,15.001735 L23,9.00520507 C23,7.90111468 22.1045695,7.00607258 21,7.00607258 L20,7.00607258 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                        transform="translate(15.000000, 12.000000) rotate(-90.000000) translate(-15.000000, -12.000000) "
                      />
                      <path
                        d="M16.7928932,9.79289322 C17.1834175,9.40236893 17.8165825,9.40236893 18.2071068,9.79289322 C18.5976311,10.1834175 18.5976311,10.8165825 18.2071068,11.2071068 L15.2071068,14.2071068 C14.8165825,14.5976311 14.1834175,14.5976311 13.7928932,14.2071068 L10.7928932,11.2071068 C10.4023689,10.8165825 10.4023689,10.1834175 10.7928932,9.79289322 C11.1834175,9.40236893 11.8165825,9.40236893 12.2071068,9.79289322 L14.5,12.0857864 L16.7928932,9.79289322 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        transform="translate(14.500000, 12.000000) rotate(-90.000000) translate(-14.500000, -12.000000) "
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >

                Kirish
              </template>
            </button>
          </div>
        </b-form>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { LOGIN, LOGOUT } from '@/core/services/store/auth.module'
import { TimelineLite } from 'gsap'
import { validationMixin } from 'vuelidate'
import { minLength, required } from 'vuelidate/lib/validators'
import router from '@/router'

const errorOptions = {
  'No active account found with the given credentials':
    "Login yoki parol noto'g'ri"
}
export default {
  mixins: [validationMixin],
  name: 'login',

  data() {
    return {
      errorOptions,

      show: true,
      loader: false,
      // Remove this dummy login info
      form: {
        username: '',
        password: ''
      },

      errorText: null
    }
  },
  validations: {
    form: {
      username: {
        required
      },
      password: {
        required,
        minLength: minLength(1)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    resetForm() {
      this.form = {
        username: null,
        password: null
      }

      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.show = false
      this.errorText = null
      // set spinner to submit button
      const submitButton = this.$refs['kt_login_signin_submit']
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right')
      const username = this.$v.form.username.$model
      const password = this.$v.form.password.$model

      // clear existing errors
      this.$store.dispatch(LOGOUT)

      this.loader = true
      // send login request
      this.$store
        .dispatch(LOGIN, { username, password })
        .then((res) => {
          // console.log('onSubmit then', res)
          this.loader = false
          this.show = true
          if (
            res &&
            res.detail &&
            res.detail == 'No active account found with the given credentials'
          ) {
            this.errorText = res.detail
            if (this.$refs.passwordRef) {
              this.$refs.passwordRef.focus()
              this.$refs.passwordRef.select()
            }
            return
          }
          if (res && res.status == 200) {
            this.$emit('isLogged')
            this.loader = false
            const { box } = this.$refs
            const timeline = new TimelineLite()

            timeline.to(box, 2, {
              x: 1500
            })
            timeline.to(
              box,
              0.5,
              {},
              '-=0.5' // Run the animation 0.5s early
            )
            setTimeout(() => {
              router.push({ path: '/' })
            }, 1300)
          }
        })
        .catch((err) => {
          console.log('onSubmit', err)
          this.loader = false
        })
      submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right')
      // }, 2000)
    }
  },

  mounted() {
    if (this.$refs.usernameRef) {
      this.$refs.usernameRef.focus()
    }
  }
}
</script>
<style scoped>
.particles__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
